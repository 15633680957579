import axiosIns from '@/libs/axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('user', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`user/${id}`)
          .then((response) => {
            if (response.data.status == 'P') {
              response.data.status = 'passive';
            } else {
              response.data.status = 'active';
            }
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    cpatPdf(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('cpatPdf', data, { responseType: 'blob' })
          //.post('inventoryReport', data )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    userCheck(ctx, { email }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`userCheck/${email}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getAllCpatData(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns

          .get('allCpatQuotes', { params: queryParams })

          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    permissionsAll(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('permissions')
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('user', userData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`user/${userData.id}`, userData)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },
    updatePassword(ctx, userData) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`user/updatePassword/${userData.id}`, userData)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    fetchPermissionsData(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('permissions', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },
    getAllSurveyResults(ctx, filterDate) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('listResult', filterDate)
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },
    deleteSurvey(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete(`deleteResult/${id}`)
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    updateArchive(ctx, survey) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`updateArchive/${survey.id}`, survey)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
  },
};
