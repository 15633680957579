<template>
  <div class="mt-2">
    <b-overlay :show="loading" rounded="sm" no-fade>
      <b-card>
        <b-row>
          <b-col xl="12" md="12" sm="12" cols="12">
            <b-row>
              <b-col xl="2" md="12" sm="12">
                <b-form-group>
                  <v-select id="event" v-model="selectedEvent" :options="eventTypes" :reduce="(val) => val" :filterable="true" @option:selected="selectedEvents" label="title" :clearable="false" input-id="event" />
                </b-form-group>
              </b-col>

              <b-col xl="2" md="12" sm="12">
                <b-form-group>
                  <v-select id="event" v-model="selectedStatus" :options="statusTypes" :reduce="(val) => val" :filterable="true" @option:selected="filterStatus" label="title" :clearable="false" input-id="event" />
                </b-form-group>
              </b-col>

              <b-col xl="3" md="12" sm="12">
                <b-form-group class="mb-40">
                  <b-input-group>
                    <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Search..."></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col xl="3" md="12" sm="12">
                <b-form-group>
                  <b-input-group>
                    <flat-pickr placeholder="Date Range" v-model="rangeDate" class="form-control" :config="{ mode: 'range', dateFormat: 'm/d/Y' }" />
                    <b-input-group-append>
                      <b-button @click="clearDate()" variant="warning">Clear</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>

              <b-col xl="2" md="12" sm="12">
                <b-form-group>
                  <b-button @click="sendProject(2)" block class="float-right" variant="warning">
                    New Survey
                  </b-button>
                </b-form-group>
              </b-col>
            </b-row>

            <b-table
              striped
              hover
              ref="refUserListTable"
              class="position-relative"
              :items="listSurveysData"
              :filter="filterQuery"
              :filter-included-fields="filterOn"
              filter-debounce="100"
              selectable
              select-mode="single"
              responsive
              :small="false"
              :fields="inHeaders"
              show-empty
              empty-text="No matching records found"
              @row-selected="onRowSelected"
              :current-page="currentPage"
              :per-page="perPage"
            >
              <!-- Column: Id -->
              <template #cell(id)="data">
                <!--   <b-link :to="{ name: 'customer-edit', params: { id: data.item.id } }" class="font-weight-bold"> #{{ data.value }} </b-link>-->
                <strong class="text-primary">#{{ data.item.id }}</strong>
              </template>

              <template #cell(firstName)="data">
                <span class="font-weight-bold">
                  {{ data.item.firstName ? data.item.firstName : 'N/A' }}
                </span>
              </template>
              <template #cell(lastName)="data">
                <span class="font-weight-bold">
                  {{ data.item.lastName ? data.item.lastName : 'N/A' }}
                </span>
              </template>

              <template #cell(gradeLevel)="data">
                <span class="font-weight-bold">
                  {{ data.item.gradeLevel ? data.item.gradeLevel.title : 'N/A' }}
                </span>
              </template>
              <template #cell(classSize)="data">
                <span class="font-weight-bold">
                  {{ data.item.classSize || data.item.classSize === 0 ? data.item.classSize : 'N/A' }}
                </span>
              </template>
              <template #cell(observer)="data">
                <span class="font-weight-bold">
                  {{ data.item.observer ? data.item.observer : 'N/A' }}
                </span>
              </template>
              <template #cell(event)="data">
                <span class="font-weight-bold">
                  {{ data.item.event ? data.item.event.title : 'N/A' }}
                </span>
              </template>
              <template #cell(isComplete)="data">
                <span class="font-weight-bold">
                  <strong :class="data.item.status == 'C' ? 'text-success' : 'text-warning'">
                    <!-- {{ data.item.status == 'C' ? 'Approved' : 'Unapproved' }} -->
                    <feather-icon
                      size="18"
                      v-b-tooltip.hover
                      :title="data.item.status == 'C' ? 'This survey has been approved' : 'This survey has been unapproved'"
                      :icon="data.item.status == 'C' ? 'CheckIcon' : 'AlertTriangleIcon'"
                      :class="data.item.status != 'C' ? 'animate__animated animate__heartBeat' : ''"
                    />
                  </strong>
                </span>
              </template>
              <template #cell(surveyDate)="data">
                <span class="font-weight-bold">
                  {{ data.item.surveyDate ? dateFormat(data.item.surveyDate) : 'N/A' }}
                </span>
              </template>

              <!-- Column: Actions -->
              <template #cell(actions)="data">
                <b-dropdown v-if="$Can('delete_survey') || $Can('see_answer')" variant="link" no-caret :right="$store.state.appConfig.isRTL">
                  <template #button-content>
                    <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                  </template>
                  <b-dropdown-item
                    :to="{
                      name: 'cpat-edit',
                      params: { id: data.item.id },
                    }"
                    v-if="$Can('see_answer')"
                  >
                    <feather-icon icon="FileTextIcon" />
                    <span class="align-middle ml-50">Details </span>
                  </b-dropdown-item>

                  <b-dropdown-item v-if="$Can('delete_survey')" @click="deleteSurvey(data.item.id)">
                    <feather-icon icon="DeleteIcon" />
                    <span class="align-middle ml-50">Delete Survey</span>
                  </b-dropdown-item>

                  <b-dropdown-item v-if="$Can('archive_authority')" @click="updateArchive(data.item.id, data.item.archive)">
                    <feather-icon icon="SendIcon" />
                    <span class="align-middle ml-50">{{ data.item.archive == 'N' ? 'Send to archive' : 'Retrieve from archive' }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>

            <div class="mx-2 mb-2">
              <b-row>
                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                  <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
                </b-col>

                <!-- Pagination -->
                <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                  <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-warning" class="mr-1" @click="showArchiveRecords()">
                    {{ archive == 'N' ? 'Show with archive records' : 'Hide with archive records' }}
                  </b-button>
                  <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
// import { BFormFile } from "bootstrap-vue";
import { BImg, BOverlay, BCard, BFormGroup, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem } from 'bootstrap-vue';
import axiosIns from '@/libs/axios';
import store from '@/store';
import router from '@/router';
import { onUnmounted } from '@vue/composition-api';
import cpatStoreModule from './cpatStoreModule';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import moment from 'moment';
import _ from 'lodash';
import vSelect from 'vue-select';
import Ripple from 'vue-ripple-directive';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import 'animate.css';
export default {
  components: {
    BImg,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BFormGroup,
    moment,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  mounted() {
    // this.getItems();
    this.getSurveyResults();
  },
  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = 'cpats';
    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, cpatStoreModule);
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });
    return { selected: ['Admin', 'User'] };
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      rangeDate: null,
      filterDateStart: null,
      filterDateEnd: null,
      file: null,
      excelJson: {},
      edit: null,
      newitem: '',
      archive: 'N',
      filter: null,
      filterEvent: null,
      filterQuery: null,
      filterOn: ['firstName', 'lastName', 'gradeLevel', 'classSize', 'observer', 'event'],
      perPage: 10,
      loading: false,
      selectedEvent: { id: 0, title: 'All Questionnaire', value: null },
      selectedStatus: { id: 0, title: 'All Status', value: null },
      items: [],
      ans: null,
      where: router.currentRoute.name,
      currentPage: 1,
      pageOptions: [5, 10, 15, 20],
      totalRows: 0,
      listSurveysData: [],
      selectedData: [],
      prevItem: null,
      answerTypes: [1, 2, 3, 4, 'Not Observed'],
      eventTypes: [{ id: 0, title: 'All Questionnaire', value: null }],
      statusTypes: [
        { id: 0, title: 'All Status', value: null },
        { id: 1, title: 'Approved', value: 'C' },
        { id: 2, title: 'Unapproved', value: 'A' },
      ],
      inHeaders: [
        { key: 'id', sortable: true, class: 'text-center' },
        {
          key: 'firstName',
          label: 'FIRST NAME',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'lastName',
          label: 'LAST NAME',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'gradeLevel',
          label: 'GRADE LEVEL',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'classSize',
          label: 'CLASS SIZE',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'observer',
          label: 'OBSERVER',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'event',
          label: 'QUESTIONNAIRE',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'surveyDate',
          label: 'DATE',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'isComplete',
          label: 'status',
          thClass: 'text-center status',
          tdClass: 'text-center status',
        },
        {
          key: 'actions',
          label: 'ACTIONS',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      colWidthFactor: null,
      tempStatusOptions: [{ label: 'Draft', value: 'A' }],
    };
  },
  computed: {
    dataMeta() {
      return {
        from: this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to: this.perPage * (this.currentPage - 1) + Number(this.perPage) < this.totalRows ? this.perPage * (this.currentPage - 1) + Number(this.perPage) : this.totalRows,
        of: this.totalRows,
      };
    },
  },
  methods: {
    takeAQuestionAnswer(ans) {
      this.ans = ans;
    },
    dateFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format('MM-DD-YYYY');
      }
    },
    selectedGroupValue(val) {
      //   let findedGroup = this.allGroupsData.find((e) => e.id == val.groupId);
      // this.surveyData.questionAnswer.forEach((question) => {
      //   if(question.questionId == Number(val.id)) {
      //       question.commentAnswer = this.ans
      //   }
      // })
    },
    downloadPdf() {
      this.download = true;
      this.loading = true;
      // store.dispatch('accounting/profitLossPdf', { start: this.filterDateStart, end: this.filterDateEnd }).then((response) => {
      let selected = this.eventTypes.filter((x) => x.name.slice(0, 3) == this.filterEvent)[0].id;
      store.dispatch('cpats/cpatPdf', { eventId: selected }).then((response) => {
        if (response.status == 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Profit Loss Statement.pdf');
          document.body.appendChild(link);
          link.click();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: '✔️ Report is created!',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });
          this.download = false;
          this.loading = false;
        }
      });
    },
    sendProject(val) {
      router.push({ name: 'settings-addSurvey' });
    },
    showQuotes(item) {
      if (this.prevItem !== null) {
        this.prevItem._showDetails = false;
        this.prevItem = item;
      } else {
        this.prevItem = item;
      }
      this.selectedData = item;
      item.dropList = 'quotes';
      item._showDetails = !item._showDetails;
    },
    onRowSelected(item) {
      if (this.$Can('see_answer')) {
        router.push({ name: 'cpat-edit', params: { id: item[0].id } });
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    clearDate() {
      if (this.rangeDate != null && this.rangeDate != '') {
        this.filterDateStart = null;
        this.filterDateEnd = null;
        this.rangeDate = null;
        this.getSurveyResults();
      }
    },
    getSurveyResults() {
      this.loading = true;
      let filter = {
        event: this.selectedEvent ? this.selectedEvent : null,
        status: this.selectedStatus ? this.selectedStatus.value : null,
        filterDate: {
          startDate: this.filterDateStart,
          endDate: this.filterDateEnd,
        },
        archive: this.archive,
      };
      store
        .dispatch('cpats/getAllSurveyResults', filter)
        .then((res) => {
         if(res.data.length > 0){

          this.totalRows = res.data.length;
          this.listSurveysData = res.data;
          res.data.forEach((element) => {
            this.eventTypes.push(element.event);
          });
          this.eventTypes = _.uniqBy(this.eventTypes, function(e) {
            return e.id;
          });

         }else{
          this.totalRows = res.data.length;
          this.listSurveysData = [];
         }
          
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    deleteSurvey(id) {
      const self = this;
      this.$swal({
        title: 'Are you sure you want to delete the survey?',
        // text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.isConfirmed) {
          store
            .dispatch('cpats/deleteSurvey', id)
            .then((res) => {
              self.getSurveyResults();
              self.$swal.fire('Deleted!', 'Survey has been deleted.', 'success');
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },

    updateArchive(id, status) {
      const self = this;
      if (status == 'N') {
        this.$swal({
          title: 'Are you sure you want to send the survey to the archive?',
          text: "Survey won't participate in calculations and graphs",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#fcb416',
          cancelButtonColor: '#ea5455 ',
          confirmButtonText: 'Yes, send to archive it!',
          customClass: {
            confirmButton: 'btn btn-warning mr-1',
            cancelButton: 'btn btn-danger',
            denyButton: 'btn btn-primary',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            store
              .dispatch('cpats/updateArchive', { id: id, status: 'Y' })
              .then((res) => {
                self.getSurveyResults();
                self.$swal.fire('Archived!', 'Survey has been sended archive', 'success');
              })
              .catch((error) => {
                console.log(error);
              });
          }
        });
      } else {
        this.$swal({
          title: 'Do you want to retrieve the survey from the archive?',
          text: 'Survey will be added to calculations and graphs.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, retrieve from archive it!',
          customClass: {
            confirmButton: 'btn btn-success mr-1',
            cancelButton: 'btn btn-danger',
            denyButton: 'btn btn-primary',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            store
              .dispatch('cpats/updateArchive', { id: id, status: 'N' })
              .then((res) => {
                self.getSurveyResults();
                self.$swal.fire('Retrieved from archive!', 'Survey has been retrieved from archive.', 'success');
              })
              .catch((error) => {
                console.log(error);
              });
          }
        });
      }
    },
    showArchiveRecords() {
      if (this.archive == 'Y') {
        this.archive = 'N';
        this.getSurveyResults();
      } else {
        this.archive = 'Y';
        this.getSurveyResults();
      }
    },

    selectedEvents(event) {
      this.filter = null;
      let filter = {
        event: event == undefined ? this.selectedEvent : event,
        status: this.selectedStatus ? this.selectedStatus.value : null,
        filterDate: {
          startDate: this.filterDateStart,
          endDate: this.filterDateEnd,
        },
        archive: this.archive,
      };
      this.loading = true;
      store
        .dispatch('cpats/getAllSurveyResults', filter)
        .then((res) => {
          this.totalRows = res.data.length;
          this.listSurveysData = res.data;
          res.data.forEach((element) => {
            this.eventTypes.push(element.event);
          });
          this.eventTypes = _.uniqBy(this.eventTypes, function(e) {
            return e.id;
          });
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    filterStatus(status) {
      this.filter = null;
      this.loading = true;
      let filter = {
        event: this.selectedEvent ? this.selectedEvent : null,
        status: this.selectedStatus ? this.selectedStatus.value : null,
        filterDate: {
          startDate: this.filterDateStart,
          endDate: this.filterDateEnd,
        },
        archive: this.archive,
      };
      store
        .dispatch('cpats/getAllSurveyResults', filter)
        .then((res) => {
          this.totalRows = res.data.length;
          this.listSurveysData = res.data;
          res.data.forEach((element) => {
            this.eventTypes.push(element.event);
          });
          this.eventTypes = _.uniqBy(this.eventTypes, function(e) {
            return e.id;
          });
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  watch: {
    rangeDate: {
      handler: function(val, before) {
        if (val != null) {
          if (val.includes('to')) {
            this.filterDateStart = this.rangeDate.split(' to ')[0];
            this.filterDateEnd = this.rangeDate.split(' to ')[1];
            this.getSurveyResults();
          }
        }
      },
    },
    filter() {
      this.filterEvent = null;
      this.filterQuery = this.filter;
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
// .table .thead-dark th {
//   background-color: #fcb416 !important;
//   border-color: #fcb416 !important;
// }
.table td {
  padding: 0.5rem;
}
.status {
  padding-left: 1rem !important;
}
.animate__animated.animate__heartBeat {
  --animate-duration: 1s;
}
</style>
